import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>

    <section className="about-header">
  <div className="about-first">
    <div className="about-header-title">
      <h1 data-aos="fade" data-aos-delay="400" data-aos-duration="1000">Thank you!
      </h1>
      </div>
    </div>
    
  </section>
  </Layout>
)
